import {
  authorizedElement,
  unauthorizedElement,
} from "../../features/Auth/utils/authConfig";
import { useEffect, useState } from "react";
import axios from "axios";

export default function useGetParticipation() {
  const [user, setUser] = useState();
  const api_link = process.env.REACT_APP_API_LINK_VLEARNED;

  useEffect(() => {
    const user_logged_in = localStorage.getItem('user_details');
    if (user_logged_in) {
      setUser(JSON.parse(user_logged_in));
    };

    GET_PARTICIPATION()
  }, [])

  // check if the logged in user has a specific role in Auth0
  const hasTeacherRole = () => user.role === "Teacher";

  const hasAdminRole = () => user.role === "Administrator" || user.role === "user";

  const hasStudentRole = () => user.role === "Student";

  const [data, setData] = useState();
  const GET_PARTICIPATION = async () => {
    try {
      const response = await axios.get(`${api_link}/special-user/${user._id}`);
      setData(response.data.user);
    } catch (error) {}
  }

  const adminOrgs = data?.getParticipation?.adminOrganizationsLength;
  const teacherOrgs = data?.getParticipation?.teacherOrganizationsLength;
  const studentOrgs = data?.getParticipation?.studentOrganizationsLength;

  const showAdminRole = hasAdminRole() && adminOrgs !== 0;
  const showTeacherRole = hasTeacherRole() && teacherOrgs !== 0;
  const showStudentRole = hasStudentRole() && studentOrgs !== 0;

  const authorized = showAdminRole || showTeacherRole || showStudentRole;

  const activeRoles = [];

  const showRole = () => {
    showAdminRole && activeRoles.push("Administrator");
    showTeacherRole && activeRoles.push("Teacher");
    showStudentRole && activeRoles.push("Student");
  };

  showRole();

  const organizationsInActiveRole = (role) => {
    if (role === "Administrator") {
      return adminOrgs;
    } else if (role === "Teacher") {
      return teacherOrgs;
    } else if (role === "Student") {
      return studentOrgs;
    }
  };

  const authStatus = authorized
    ? authorizedElement.status
    : unauthorizedElement.status;

  const authStatusDescription = authorized
    ? authorizedElement.description
    : unauthorizedElement.description;

  const icon = authorized ? authorizedElement.icon : unauthorizedElement.icon;

  return {
    authorized,
    activeRoles,
    organizationsInActiveRole,
    meta: {
      authStatus,
      authStatusDescription,
      icon,
    },
  };
}
