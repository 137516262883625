import { TextField, Button, Container, Box, Snackbar, CircularProgress } from '@material-ui/core';
import logo from '../../assets/vlearned-final-logo.webp'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import { useEffect, useState } from 'react';
import axios from 'axios';
import { Alert } from '@material-ui/lab';

import Cookies from 'js-cookie';

const Activataccount = () => {
  const api_link = process.env.REACT_APP_API_LINK_VLEARNED
  const [token, setToken] = useState('');
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const user_token = localStorage.getItem('user_token');

  useEffect(() => {
    console.log(user_token)
  }, [])

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const [loading, setLoading] = useState(false);
  const handleVerification = (e) => {
    e.preventDefault();
    setLoading(true);

    var options = {
      method: 'POST',
      url: `${api_link}/users/activate-account`,
      data: { token: token, user_token: user_token }
    };

    axios.request(options).then(function (response) {
      if (response.data.status === 200) {
        const user_id = response.data.user_id;
        localStorage.setItem('user_id', user_id);
        // , { expires: 7, sameSite: 'None', domain: '.vlearned.com' }
        handleClick();

        setErrorMessage('Success! Verification finished, login now.');
        setLoading(false);
        setTimeout(() => {
          window.location.href = "/login"
        }, 2000);
      } else {
        setErrorMessage(`Error: ${response.data.error}`);
        setOpen(true);
        setLoading(false);
      }
    }).catch(function (error) {
      console.log(error);
      setLoading(false);
      setErrorMessage('Error: Registering user');
    });
  }

  return (
    <Container maxWidth="sm" className='container-flex'>
      <Box height={500} width={'100%'} my={4} display="flex" alignItems="center" gap={4} p={2} alignSelf="center">
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={errorMessage.startsWith('Error:') ? 'error' : 'success'} id='errorMessage'>
            {errorMessage}
          </Alert>
        </Snackbar>

        <form className='account-bg-white'>
          <center><Link to="/"><img src={logo} alt="logo" width={240} /></Link></center>

          <p className='subhead'>Enter token to verify.</p>

          <TextField type='text' id="outlined-basic" label="Token" required variant="outlined" className='w-100' onChange={(e) => setToken(e.target.value)} />

          {loading ? (
            <Button variant="contained" color="primary" className='mt-7' disabled>
              <CircularProgress size={24} />
            </Button>
          ) : (
            <Button variant="contained" onClick={handleVerification} color="primary" className='mt-7'>Get token</Button>
          )}

          <p className='option'><Link to='/login' className='link text-danger'>Go back</Link></p>
        </form>
      </Box>
    </Container>
  )
}

export default Activataccount