import React, { useState, useEffect } from 'react';
import { TextField, Button, Container, Box, InputAdornment, IconButton, Snackbar, CircularProgress } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import logo from '../../assets/vlearned-final-logo.webp';
import { Link, useHistory } from 'react-router-dom';
import axios from 'axios';
import { Alert } from '@material-ui/lab';

const Login = () => {
  const api_link = process.env.REACT_APP_API_LINK_VLEARNED;
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpen(false);
  };

  useEffect(() => {
    const userDetails = localStorage.getItem('user_details');
    const user = userDetails ? JSON.parse(userDetails) : null;

    if (user) {
      switch (user.role) {
        case 'Super Administrator':
          history.push(`/super-admin/${user._id}/dashboard`);
          break;
        case 'Teacher':
          history.push(`/teacher/${user.teacherId}/${user.organizationType}/${user.organizationId}/next-steps`);
          break;
        case 'Student':
          history.push(`/student/${user.studentId}/${user.organizationType}/${user.organizationId}/next-steps`);
          break;
        case 'Administrator':
          history.push(`/admin/${user._id}/next-steps`);
          break;
        default:
          history.push(`/admin/${user._id}/next-steps`);
          break;
      }
    }
  }, [history]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(`${api_link}/users/login`, { email, password });

      if (response.data.status === 200) {
        const user_details = response.data.user;
        const user_info = {
          name: user_details.name,
          email: user_details.email,
          _id: user_details._id,
          phone: user_details.phone,
          status: user_details.status,
          role: user_details.role,
          user_token: user_details.user_token,
        };

        localStorage.setItem('user_details', JSON.stringify(user_info));
        setErrorMessage('Success! Welcome to Vlearned.');
        setOpen(true);

        // Redirect based on user role
        switch (user_details.role) {
          case 'Administrator':
            const organizationResponse = await axios.get(`${api_link}/organizations/user-organization/${user_details._id}`);
            const organization = organizationResponse.data.data[0];
            const combinedAdminDetails = { ...user_info, organizationId: organization._id, organizationType: organization.type };
            localStorage.setItem('user_details', JSON.stringify(combinedAdminDetails));
            history.push(`/admin/${user_info._id}/next-steps`);
            break;
          case 'Super Administrator':
            history.push(`/super-admin/${user_info._id}/dashboard`);
            break;
          case 'Student':
            const studentResponse = await axios.get(`${api_link}/students/user-student/${user_details._id}`);
            const student = studentResponse.data.data;
            const combinedStudentDetails = { ...user_info, organizationId: student.organizationId._id, organizationType: student.organizationId.type, studentId: student._id };
            localStorage.setItem('user_details', JSON.stringify(combinedStudentDetails));
            history.push(`/student/${student._id}/${student.organizationId.type}/${student.organizationId._id}/next-steps`);
            break;
          case 'Teacher':
            const teacherResponse = await axios.get(`${api_link}/instructors/user-instructor/${user_details._id}`);
            const teacher = teacherResponse.data.data;
            const combinedTeacherDetails = { ...user_info, organizationType: teacher.organisationId.type, organizationId: teacher.organisationId._id, teacherId: teacher._id };
            localStorage.setItem('user_details', JSON.stringify(combinedTeacherDetails));
            history.push(`/teacher/${teacher._id}/${teacher.organisationId.type}/${teacher.organisationId._id}/next-steps`);
            break;
          default:
            history.push(`/admin/${user_info._id}/next-steps`);
            break;
        }
      } else {
        setErrorMessage(`Error: ${response.data.error}`);
        setOpen(true);
      }
    } catch (error) {
      setErrorMessage('Error: ' + (error.response ? error.response.data.error : 'Network Error'));
      setOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const userDetails = localStorage.getItem('user_details');
  if (userDetails) return null;

  return (
    <Container maxWidth="sm" style={{ display: "flex", justifyContent: "center", alignItems: 'center', height: '100vh' }}>
      <Box height={500} width={'100%'} gap={4} p={2}>
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={errorMessage.startsWith('Error:') ? 'error' : 'success'}>
            {errorMessage}
          </Alert>
        </Snackbar>

        <form className='account-bg-white'>
          <center><Link to='/'><img src={logo} alt="logo" width={240} /></Link></center>
          <p className='head'>Welcome!</p>
          <p className='subhead'>Signup to VLEARNED to get started</p>

          <TextField type='email' label="Email" required variant="outlined" fullWidth onChange={(e) => setEmail(e.target.value)} style={{ marginBottom: 10 }} />
          <TextField type={showPassword ? 'text' : 'password'} label="Password" required variant="outlined" fullWidth onChange={(e) => setPassword(e.target.value)} 
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }} 
          />
          <p className='option2'><Link className='link' to='/forgot-password'>Forgot Password</Link></p>

          <Button variant="contained" color="primary" className='mt-7' onClick={handleLogin} disabled={loading}>
            {loading ? <CircularProgress size={24} /> : 'Login'}
          </Button>

          <p className='option'>Don't have an account? <Link to='/register' className='link text-danger'>Register</Link></p>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
