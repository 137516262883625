import React, { lazy, useEffect } from "react";
import "./App.css";
import "./index.scss";
import { Switch, Route, Redirect } from "react-router-dom";
// import { useAuth0 } from "@auth0/auth0-react";
import CircleSpinner from "./components/CircleSpinner/CircleSpinner";
// import ProtectedRoute from "./auth/protected-route";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import NetworkErrorPage from "./pages/NetworkErrorPage/NetworkErrorPage";
import RequestErrorPage from "./pages/RequestErrorPage/RequestErrorPage";
import UploadErrorPage from "./pages/UploadErrorPage/UploadErrorPage";
import UnavailableServerErrorPage from "./pages/UnavailableServerErrorPage/UnavailableServerErrorPage";
import DeleteFileErrorPage from "./pages/DeleteFileErrorPage/DeleteFileErrorPage";
import BadRequest from "./pages/ErrorPages/BadRequest";
import InternalServer from "./pages/ErrorPages/InternalServer";
import GatewayTimeout from "./pages/ErrorPages/GatewayTimeout";
import LMSCallbackPage from "./pages/LMSCallbackPage/LMSCallbackPage";
import AuthRolesPage from "./pages/AuthRolesPage/AuthRolesPage";
import SelfCreateOrganizationPage from "./pages/SelfCreateOrganizationPage/SelfCreateOrganizationPage";
import MyOrganizationsPage from "./pages/MyOrganizationsPage/MyOrganizationsPage";
// import Landing from "./pages/LandingPage/LandingPage";
import CareersPage from "./pages/CareersPage/CareersPage";
import JobAdPagePublic from "./pages/HumanResource/JobAdPagePublic";

import Login from "./pages/security/login";
import Register from "./pages/security/Register";
import Forgotpassword from "./pages/security/Forgot-password";
import VerifyToken from "./pages/security/verify-token";
import Resetpassword from "./pages/security/Reset-password";
import Activataccount from "./pages/security/activate-account";
import Verifyactivation from "./pages/security/verify-activation";

import Cookies from 'js-cookie';
import SuperAdmin from "./layouts/Super-admin/Superadmin";
import AuthScriptComponent from "./AuthScript";

// Lazy load only the specified components
const Admin = lazy(() => import("./layouts/Admin/Admin"));
const Student = lazy(() => import("./layouts/Student/Student"));
const Teacher = lazy(() => import("./layouts/Teacher/Teacher"));

const App = () => {
  const api_link = process.env.REACT_APP_API_LINK_VLEARNED;
  
  const isLoading = false;
  const userDetails = localStorage.getItem('user_details');
  const user = userDetails ? JSON.parse(userDetails) : null;

  const roles = user?.role;

  if (isLoading) {
    return <CircleSpinner loading={isLoading} />;
  }

  // const hasTeacherRole = () => roles && roles.some((role) => role === "Teacher");
  const hasTeacherRole = () => roles && roles === "Teacher";

  const hasAdminRole = () => roles && roles === "Administrator";

  const hasStudentRole = () => roles && roles === "Student";

  return (
    <>
      <Switch>
        {/* <ProtectedRoute path="/admin" component={Admin} /> */}
        {/* <ProtectedRoute path="/teacher/:TEACHER_ID" component={Teacher} />
        <ProtectedRoute path="/student/:STUDENT_ID" component={Student} />
        <ProtectedRoute path="/callback" component={LMSCallbackPage} />
        <ProtectedRoute exact path="/role-auth" component={AuthRolesPage} />
        <ProtectedRoute exact path="/self-create-organization" component={SelfCreateOrganizationPage} />
        <ProtectedRoute path="/teacher-organizations" component={MyOrganizationsPage} />
        <ProtectedRoute path="/student-organizations" component={MyOrganizationsPage} />
        <ProtectedRoute path="/parent-organizations" component={MyOrganizationsPage} /> */}

        <Route path="/super-admin/*" component={SuperAdmin} />
        <Route exact path="/admin/*" component={Admin} />
        <Route path="/student/*" component={Student} />
        <Route path="/teacher/*" component={Teacher} />
        <Route path="/callback" component={LMSCallbackPage} />
        <Route exact path="/role-auth/*" component={AuthRolesPage} />
        <Route exact path="/self-create-organization/*" component={SelfCreateOrganizationPage} />
        <Route path="/teacher-organizations/*" component={MyOrganizationsPage} />
        <Route path="/student-organizations/*" component={MyOrganizationsPage} />
        <Route path="/parent-organizations/*" component={MyOrganizationsPage} />

        <Route exact path="/" component={Login} />
        {/* <Route exact path="/" component={Landing} /> */}
        <Route exact path="/careers" component={CareersPage} />
        <Route exact path="/careers/:JOB_ID" component={JobAdPagePublic} />
        {!hasAdminRole() && !hasTeacherRole() && !hasStudentRole() && user && (
          <Redirect from="/role-auth" to="/role-auth" />
        )}
        <Route path="/400" component={BadRequest} />
        <Route path="/500" component={InternalServer} />
        <Route path="/504" component={GatewayTimeout} />
        <Route path="/unavailableServer-error" component={UnavailableServerErrorPage} />
        <Route path="/deleteFile-error" component={DeleteFileErrorPage} />
        <Route path="/upload-error" component={UploadErrorPage} />
        <Route path="/request-error" component={RequestErrorPage} />
        <Route path="/network-error" component={NetworkErrorPage} />

        <Route path="/login" component={Login} />
        <Route path='/register' component={Register} />
        <Route path="/forgot-password" component={Forgotpassword} />
        <Route path="/verify-token" component={VerifyToken} />
        <Route path="/reset-password" component={Resetpassword} />
        <Route path="/activate-account" component={Activataccount} />
        <Route path="/verify-activation" component={Verifyactivation} />

        <Route path="/404" component={NotFoundPage} />
        <Route component={NotFoundPage} />
      </Switch>
      <AuthScriptComponent />
    </>
  );
};

export default App;
